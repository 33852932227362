import React from "react";

const SellYourWavHeader = () => {
  return (
    <div className="sell-your-wav-header">
      <div className="wrapper">
        <div className="text-wrapper">
          <h1>SELL YOUR WAV</h1>
          <div className="subtitle">
            Sell your WAVs quickly & easily by choosing an Ad Plan that meets
            your needs


          </div>
        </div>
      </div>
    </div>
  );
};

export default SellYourWavHeader;
